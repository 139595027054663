import React from "react"

interface WebpProps {
  webpSrc: string
  gifSrc: string
}

const Webp: React.FC<WebpProps> = ({ webpSrc, gifSrc, ...props }) => {
  // TODO: use IntersectionObserver
  return (
    <picture>
      <source srcSet={webpSrc} type="image/webp" />
      <source srcSet={gifSrc} type="image/gif" />
      <img src={gifSrc} loading="lazy" alt="" {...props} />
    </picture>
  )
}

export default Webp

import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { GatsbyPage } from "src/types"
import Layout from "src/layout"
import { EMAIL_ADDRESS, BREAKPOINTS } from "src/constants"
import {
  Section,
  Subtitle,
  Title,
  Description,
  SectionTitle,
  Text,
  Heading,
  InfosContainer,
  Infos,
  InfosImage,
  InfosContent,
  InfosTitle,
  InfosText,
  Content,
} from "src/components/styled"
import Webp from "src/components/Webp"
import { Splash, Parallax } from "src/components/Parallax"
import { scrollToRef } from "src/helpers/window"
import BookForm, { Action } from "src/components/BookForm"
import FaqLink from "src/components/FaqLink"

import GIFTCARD_WEBP from "src/images/gift/giftcard.webp"
import GIFTCARD_GIF from "src/images/gift/giftcard.gif"
import ORIGAMI_WEBP from "src/images/gift/origami.webp"
import ORIGAMI_GIF from "src/images/gift/origami.gif"

const Gif = styled(Webp)`
  width: 500px;
  margin-bottom: 30px;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    width: 100%;
    margin-bottom: 20px;
  }
`
const Small = styled.p`
  max-width: ${BREAKPOINTS.maxTextWidth}px;
`

const GiftPage: GatsbyPage = ({ data }) => {
  return (
    <Layout title="Offrir">
      <Splash>
        <Parallax desktop={data.splashDesktop.childImageSharp} mobile={data.splashMobile.childImageSharp} />
      </Splash>
      <Content>
        <Section>
          <Subtitle>OFFRIR UN ATELIER</Subtitle>
          <Title ref={scrollToRef}>
            <span>
              Pour les fêtes, un anniversaire, une crémaillère…
              <br />
              Offrez un atelier !
            </span>
          </Title>
          <Description>
            Moins de gaspillage, offrez à vos proches du temps pour construire l’objet qui leur manque vraiment et qui
            n’existe pas encore. Nos cartes cadeaux sont disponibles en format numérique ou papier.
          </Description>
        </Section>
        <Section>
          <SectionTitle>Commander sa carte cadeau atelier</SectionTitle>
          <Text>
            <Heading>Carte cadeau numérique</Heading>
            <br />
            Une carte cadeau numérique au format PDF vous sera instantanément envoyée par e-mail. Cette carte peut
            rester au format numérique ou être imprimée et pliée à la façon d’un origami pour être offerte en main
            propre (<a href="/notice.pdf">notice de pliage visible ici</a>).
          </Text>
          <Gif webpSrc={ORIGAMI_WEBP} gifSrc={ORIGAMI_GIF} />
          <Text>
            <Heading>En option : l’envoi par courrier (délai 5 jours ouvrés)</Heading>
            <br />
            Vous pouvez aussi choisir l’option d’envoi postal. Dans ce cas, en plus de la carte numérique envoyée par
            e-mail instantanément, vous recevrez une jolie carte cadeau accompagnée d’une petite chute de bois pour
            donner un avant goût de l’atelier, le tout dans une petit pochette en tissu prête à offrir.
            <br />
            <br />
            Les cartes sont envoyées par courrier en 5 jours ouvrés environ. Attention avec les fêtes ce délai peut-être
            allongé et il peut arriver que les délais postaux soient plus long, nous nous en excusons par avance.
          </Text>
          <Gif webpSrc={GIFTCARD_WEBP} gifSrc={GIFTCARD_GIF} />
        </Section>
        <Section>
          <SectionTitle>Utiliser sa carte cadeau atelier</SectionTitle>
          <Text>
            Les cartes cadeaux sont valables 1 an ou plus* à compter de la date d’achat. Chaque carte possède un code
            unique permettant au bénéficiaire de s’inscrire directement sur notre site internet. Cette carte peut être
            utilisée pour l’atelier choisi ou comme bon d’achat pour s’inscrire sur l’ensemble de notre boutique. Il
            vous suffira d’entrer le code dans la case « code cadeau ou réduction » de votre panier.
          </Text>
          <Small>
            * Des extensions de validité sont prévues en cas de fermetures exceptionnelles liées aux contraintes
            sanitaires.
          </Small>
        </Section>
        <BookForm action={Action.offer} workshops={data.workshops.nodes} name="Atelier Chutes Libres" />
        <Section>
          <FaqLink />
        </Section>
        <InfosContainer>
          <Infos>
            <InfosImage file={data.infos.childImageSharp} alt="" />
            <InfosContent>
              <InfosTitle>Nous répondons à vos questions sur les cartes cadeaux atelier :</InfosTitle>
              <InfosText>
                &gt; Puis-je utiliser ma carte cadeau sur toute votre boutique ?
                <ol>
                  <li>
                    Oui, votre carte cadeau peut être utilisée comme bon d’achat d’une valeur égale à celle de l’atelier
                    indiqué sur votre carte. Il vous suffira, au moment de régler vos achats, d’inscrire votre code
                    cadeau et de payer la différence en carte bancaire si le montant de votre panier dépasse celui
                    disponible sur votre carte. En revanche ce code cadeau n’est utilisable qu’une seule fois.
                  </li>
                </ol>
              </InfosText>
              <InfosText>
                &gt; J’ai reçu une carte cadeau atelier, comment puis-je l’utiliser ?
                <ol>
                  <li>
                    Rendez-vous en bas de la page de l’atelier qui vous intéresse et cliquez sur le bouton «  réserver
                    un atelier ». Choisissez la formule et la date d’atelier qui vous convient puis cliquez sur le
                    bouton « ajouter au panier ».
                  </li>
                  <li>
                    Vous êtes redirigé·e automatiquement sur la page « Ma commande ». Sur cette page, vous pouvez
                    inscrire votre code dans le carré rouge qui indique « code cadeau ou réduction  ». Cliquez ensuite
                    sur « appliquer ». Après quelques secondes de vérification, vous verrez apparaître la réduction
                    directement dans le total de vos commandes.
                  </li>
                  <li>
                    Si votre sélection d’achats est terminée, vous pouvez cliquer sur le bouton « commander  » pour
                    procéder à la réservation. Suivez ensuite la procédure d’inscription jusqu’à la réception d’un
                    e-mail de confirmation. Si le montant de la commande dépasse la valeur de la carte cadeau, vous
                    devrez payer la différence en carte bancaire.
                  </li>
                </ol>
              </InfosText>
              <InfosText>
                &gt; Je n’arrive pas à utiliser mon code de carte cadeau, que puis-je faire ?
                <ol>
                  <li>
                    Vérifiez la date de validité indiquée sur votre carte cadeau. Elle est valable un an à partir de la
                    date d’achat.
                  </li>
                  <li>Vérifiez que vous avez bien respecté les étapes présentées à la question précédente.</li>
                  <li>
                    Si un problème persiste, contactez les ateliers par e-mail (
                    <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>).
                  </li>
                </ol>
              </InfosText>
            </InfosContent>
          </Infos>
        </InfosContainer>
      </Content>
    </Layout>
  )
}

export default GiftPage

export const query = graphql`
  query {
    splashDesktop: file(relativePath: { eq: "gift/splash-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    splashMobile: file(relativePath: { eq: "gift/splash-mobile.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wood: file(relativePath: { eq: "values/planches.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    infos: file(relativePath: { eq: "infos.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, traceSVG: { background: "#E10F14", color: "#FFFFFF" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    workshops: allContentfulWorkshop(sort: { fields: [position], order: ASC }) {
      nodes {
        slug
        name
        formulas {
          id: contentful_id
          name
          prefix
          price
          description
        }
      }
    }
  }
`
